
    <template>
      <section class="content element-doc">
        <h2>Input 输入框</h2>
<p>通过鼠标或键盘输入字符</p>
<div class="warning">
<p>Input 为受控组件，它<strong>总会显示 Vue 绑定值</strong>。</p>
<p>通常情况下，应当处理 <code>input</code> 事件，并更新组件的绑定值（或使用<code>v-model</code>）。否则，输入框内显示的值将不会改变。</p>
<p>不支持 <code>v-model</code> 修饰符。</p>
</div>
<h3>基础用法</h3>
<demo-block>
        
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-input v-model=&quot;input&quot; placeholder=&quot;请输入内容&quot;&gt;&lt;/el-input&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const input = ref('')

      return { input }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>禁用状态</h3>
<demo-block>
        <div><p>通过 <code>disabled</code> 属性指定是否禁用 input 组件</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-input placeholder=&quot;请输入内容&quot; v-model=&quot;input&quot; :disabled=&quot;true&quot;&gt; &lt;/el-input&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const input = ref('')

      return { input }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>可清空</h3>
<demo-block>
        <div><p>使用<code>clearable</code>属性即可得到一个可清空的输入框</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-input placeholder=&quot;请输入内容&quot; v-model=&quot;input&quot; clearable&gt; &lt;/el-input&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const input = ref('')

      return { input }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>密码框</h3>
<demo-block>
        <div><p>使用<code>show-password</code>属性即可得到一个可切换显示隐藏的密码框</p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-input placeholder=&quot;请输入密码&quot; v-model=&quot;input&quot; show-password&gt;&lt;/el-input&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const input = ref('')

      return { input }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>带 icon 的输入框</h3>
<p>带有图标标记输入类型</p>
<demo-block>
        <div><p>可以通过 <code>prefix-icon</code> 和 <code>suffix-icon</code> 属性在 input 组件首部和尾部增加显示图标，也可以通过 slot 来放置图标。</p>
</div>
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div class=&quot;demo-input-suffix&quot;&gt;
  属性方式：
  &lt;el-input
    placeholder=&quot;请选择日期&quot;
    suffix-icon=&quot;el-icon-date&quot;
    v-model=&quot;input1&quot;
  &gt;
  &lt;/el-input&gt;
  &lt;el-input
    placeholder=&quot;请输入内容&quot;
    prefix-icon=&quot;el-icon-search&quot;
    v-model=&quot;input2&quot;
  &gt;
  &lt;/el-input&gt;
&lt;/div&gt;
&lt;div class=&quot;demo-input-suffix&quot;&gt;
  slot 方式：
  &lt;el-input placeholder=&quot;请选择日期&quot; v-model=&quot;input3&quot;&gt;
    &lt;template v-slot:suffix&gt;
      &lt;i class=&quot;el-input__icon el-icon-date&quot;&gt;&lt;/i&gt;
    &lt;/template&gt;
  &lt;/el-input&gt;
  &lt;el-input placeholder=&quot;请输入内容&quot; v-model=&quot;input4&quot;&gt;
    &lt;template v-slot:prefix&gt;
      &lt;i class=&quot;el-input__icon el-icon-search&quot;&gt;&lt;/i&gt;
    &lt;/template&gt;
  &lt;/el-input&gt;
&lt;/div&gt;

&lt;script&gt;
  export default {
    data() {
      return {
        input1: '',
        input2: '',
        input3: '',
        input4: ''
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>文本域</h3>
<p>用于输入多行文本信息，通过将 <code>type</code> 属性的值指定为 textarea。</p>
<demo-block>
        <div><p>文本域高度可通过 <code>rows</code> 属性控制</p>
</div>
        <template #source><element-demo5 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-input type=&quot;textarea&quot; :rows=&quot;2&quot; placeholder=&quot;请输入内容&quot; v-model=&quot;textarea&quot;&gt;
&lt;/el-input&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const textarea = ref('')

      return { textarea }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>可自适应文本高度的文本域</h3>
<p>通过设置 <code>autosize</code> 属性可以使得文本域的高度能够根据文本内容自动进行调整，并且 <code>autosize</code> 还可以设定为一个对象，指定最小行数和最大行数。</p>
<demo-block>
        
        <template #source><element-demo6 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-input type=&quot;textarea&quot; autosize placeholder=&quot;请输入内容&quot; v-model=&quot;textarea1&quot;&gt;
&lt;/el-input&gt;
&lt;div style=&quot;margin: 20px 0;&quot;&gt;&lt;/div&gt;
&lt;el-input
  type=&quot;textarea&quot;
  :autosize=&quot;{ minRows: 2, maxRows: 4}&quot;
  placeholder=&quot;请输入内容&quot;
  v-model=&quot;textarea2&quot;
&gt;
&lt;/el-input&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const textarea1 = ref('')
      const textarea2 = ref('')

      return {
        textarea1,
        textarea2
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>复合型输入框</h3>
<p>可前置或后置元素，一般为标签或按钮</p>
<demo-block>
        <div><p>可通过 slot 来指定在 input 中前置或者后置内容。</p>
</div>
        <template #source><element-demo7 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div&gt;
  &lt;el-input placeholder=&quot;请输入内容&quot; v-model=&quot;input1&quot;&gt;
    &lt;template v-slot:prepend&gt;Http://&lt;/template&gt;
  &lt;/el-input&gt;
&lt;/div&gt;
&lt;div style=&quot;margin-top: 15px;&quot;&gt;
  &lt;el-input placeholder=&quot;请输入内容&quot; v-model=&quot;input2&quot;&gt;
    &lt;template v-slot:append&gt;.com&lt;/template&gt;
  &lt;/el-input&gt;
&lt;/div&gt;
&lt;div style=&quot;margin-top: 15px;&quot;&gt;
  &lt;el-input placeholder=&quot;请输入内容&quot; v-model=&quot;input3&quot; class=&quot;input-with-select&quot;&gt;
    &lt;template v-slot:prepend&gt;
      &lt;el-select v-model=&quot;select&quot; placeholder=&quot;请选择&quot;&gt;
        &lt;el-option label=&quot;餐厅名&quot; value=&quot;1&quot;&gt;&lt;/el-option&gt;
        &lt;el-option label=&quot;订单号&quot; value=&quot;2&quot;&gt;&lt;/el-option&gt;
        &lt;el-option label=&quot;用户电话&quot; value=&quot;3&quot;&gt;&lt;/el-option&gt;
      &lt;/el-select&gt;
    &lt;/template&gt;
    &lt;template v-slot:append&gt;
      &lt;el-button icon=&quot;el-icon-search&quot;&gt;&lt;/el-button&gt;
    &lt;/template&gt;
  &lt;/el-input&gt;
&lt;/div&gt;
&lt;style&gt;
  .el-select .el-input {
    width: 130px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
&lt;/style&gt;
&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const input1 = ref('')
      const input2 = ref('')
      const input3 = ref('')
      const select = ref('')

      return {
        input1,
        input2,
        input3,
        select
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>尺寸</h3>
<demo-block>
        <div><p>可通过 <code>size</code> 属性指定输入框的尺寸，除了默认的大小外，还提供了 large、small 和 mini 三种尺寸。</p>
</div>
        <template #source><element-demo8 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;div class=&quot;demo-input-size&quot;&gt;
  &lt;el-input
    placeholder=&quot;请输入内容&quot;
    suffix-icon=&quot;el-icon-date&quot;
    v-model=&quot;input1&quot;
  &gt;
  &lt;/el-input&gt;
  &lt;el-input
    size=&quot;medium&quot;
    placeholder=&quot;请输入内容&quot;
    suffix-icon=&quot;el-icon-date&quot;
    v-model=&quot;input2&quot;
  &gt;
  &lt;/el-input&gt;
  &lt;el-input
    size=&quot;small&quot;
    placeholder=&quot;请输入内容&quot;
    suffix-icon=&quot;el-icon-date&quot;
    v-model=&quot;input3&quot;
  &gt;
  &lt;/el-input&gt;
  &lt;el-input
    size=&quot;mini&quot;
    placeholder=&quot;请输入内容&quot;
    suffix-icon=&quot;el-icon-date&quot;
    v-model=&quot;input4&quot;
  &gt;
  &lt;/el-input&gt;
&lt;/div&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const input1 = ref('')
      const input2 = ref('')
      const input3 = ref('')
      const input4 = ref('')

      return {
        input1,
        input2,
        input3,
        input4
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><!-- ### 带输入建议

根据输入内容提供对应的输入建议

:::demo autocomplete 是一个可带输入建议的输入框组件，`fetch-suggestions` 是一个返回输入建议的方法属性，如 querySearch(queryString, cb)，在该方法中你可以在你的输入建议数据准备好时通过 cb(data) 返回到 autocomplete 组件中。
```html
<el-row class="demo-autocomplete">
  <el-col :span="12">
    <div class="sub-title">激活即列出输入建议</div>
    <el-autocomplete
      class="inline-input"
      v-model="state1"
      :fetch-suggestions="querySearch"
      placeholder="请输入内容"
      @select="handleSelect"
    ></el-autocomplete>
  </el-col>
  <el-col :span="12">
    <div class="sub-title">输入后匹配输入建议</div>
    <el-autocomplete
      class="inline-input"
      v-model="state2"
      :fetch-suggestions="querySearch"
      placeholder="请输入内容"
      :trigger-on-focus="false"
      @select="handleSelect"
    ></el-autocomplete>
  </el-col>
</el-row>
<script>
  export default {
    data() {
      return {
        restaurants: [],
        state1: '',
        state2: ''
      };
    },
    methods: {
      querySearch(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      loadAll() {
        return [
          { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
          { "value": "Hot honey 首尔炸鸡（仙霞路）", "address": "上海市长宁区淞虹路661号" },
          { "value": "新旺角茶餐厅", "address": "上海市普陀区真北路988号创邑金沙谷6号楼113" },
          { "value": "泷千家(天山西路店)", "address": "天山西路438号" },
          { "value": "胖仙女纸杯蛋糕（上海凌空店）", "address": "上海市长宁区金钟路968号1幢18号楼一层商铺18-101" },
          { "value": "贡茶", "address": "上海市长宁区金钟路633号" },
          { "value": "豪大大香鸡排超级奶爸", "address": "上海市嘉定区曹安公路曹安路1685号" },
          { "value": "茶芝兰（奶茶，手抓饼）", "address": "上海市普陀区同普路1435号" },
          { "value": "十二泷町", "address": "上海市北翟路1444弄81号B幢-107" },
          { "value": "星移浓缩咖啡", "address": "上海市嘉定区新郁路817号" },
          { "value": "阿姨奶茶/豪大大", "address": "嘉定区曹安路1611号" },
          { "value": "新麦甜四季甜品炸鸡", "address": "嘉定区曹安公路2383弄55号" },
          { "value": "Monica摩托主题咖啡店", "address": "嘉定区江桥镇曹安公路2409号1F，2383弄62号1F" },
          { "value": "浮生若茶（凌空soho店）", "address": "上海长宁区金钟路968号9号楼地下一层" },
          { "value": "NONO JUICE  鲜榨果汁", "address": "上海市长宁区天山西路119号" },
          { "value": "CoCo都可(北新泾店）", "address": "上海市长宁区仙霞西路" },
          { "value": "快乐柠檬（神州智慧店）", "address": "上海市长宁区天山西路567号1层R117号店铺" },
          { "value": "Merci Paul cafe", "address": "上海市普陀区光复西路丹巴路28弄6号楼819" },
          { "value": "猫山王（西郊百联店）", "address": "上海市长宁区仙霞西路88号第一层G05-F01-1-306" },
          { "value": "枪会山", "address": "上海市普陀区棕榈路" },
          { "value": "纵食", "address": "元丰天山花园(东门) 双流路267号" },
          { "value": "钱记", "address": "上海市长宁区天山西路" },
          { "value": "壹杯加", "address": "上海市长宁区通协路" },
          { "value": "唦哇嘀咖", "address": "上海市长宁区新泾镇金钟路999号2幢（B幢）第01层第1-02A单元" },
          { "value": "爱茜茜里(西郊百联)", "address": "长宁区仙霞西路88号1305室" },
          { "value": "爱茜茜里(近铁广场)", "address": "上海市普陀区真北路818号近铁城市广场北区地下二楼N-B2-O2-C商铺" },
          { "value": "鲜果榨汁（金沙江路和美广店）", "address": "普陀区金沙江路2239号金沙和美广场B1-10-6" },
          { "value": "开心丽果（缤谷店）", "address": "上海市长宁区威宁路天山路341号" },
          { "value": "超级鸡车（丰庄路店）", "address": "上海市嘉定区丰庄路240号" },
          { "value": "妙生活果园（北新泾店）", "address": "长宁区新渔路144号" },
          { "value": "香宜度麻辣香锅", "address": "长宁区淞虹路148号" },
          { "value": "凡仔汉堡（老真北路店）", "address": "上海市普陀区老真北路160号" },
          { "value": "港式小铺", "address": "上海市长宁区金钟路968号15楼15-105室" },
          { "value": "蜀香源麻辣香锅（剑河路店）", "address": "剑河路443-1" },
          { "value": "北京饺子馆", "address": "长宁区北新泾街道天山西路490-1号" },
          { "value": "饭典*新简餐（凌空SOHO店）", "address": "上海市长宁区金钟路968号9号楼地下一层9-83室" },
          { "value": "焦耳·川式快餐（金钟路店）", "address": "上海市金钟路633号地下一层甲部" },
          { "value": "动力鸡车", "address": "长宁区仙霞西路299弄3号101B" },
          { "value": "浏阳蒸菜", "address": "天山西路430号" },
          { "value": "四海游龙（天山西路店）", "address": "上海市长宁区天山西路" },
          { "value": "樱花食堂（凌空店）", "address": "上海市长宁区金钟路968号15楼15-105室" },
          { "value": "壹分米客家传统调制米粉(天山店)", "address": "天山西路428号" },
          { "value": "福荣祥烧腊（平溪路店）", "address": "上海市长宁区协和路福泉路255弄57-73号" },
          { "value": "速记黄焖鸡米饭", "address": "上海市长宁区北新泾街道金钟路180号1层01号摊位" },
          { "value": "红辣椒麻辣烫", "address": "上海市长宁区天山西路492号" },
          { "value": "(小杨生煎)西郊百联餐厅", "address": "长宁区仙霞西路88号百联2楼" },
          { "value": "阳阳麻辣烫", "address": "天山西路389号" },
          { "value": "南拳妈妈龙虾盖浇饭", "address": "普陀区金沙江路1699号鑫乐惠美食广场A13" }
        ];
      },
      handleSelect(item) {
        console.log(item);
      }
    },
    mounted() {
      this.restaurants = this.loadAll();
    }
  }
</script>
```
:::

### 自定义模板

可自定义输入建议的显示

:::demo 使用`scoped slot`自定义输入建议的模板。该 scope 的参数为`item`，表示当前输入建议对象。
```html
<el-autocomplete
  v-if="false"
  popper-class="my-autocomplete"
  v-model="state"
  :fetch-suggestions="querySearch"
  placeholder="请输入内容"
  @select="handleSelect">
  <i
    class="el-icon-edit el-input__icon"
    slot="suffix"
    @click="handleIconClick">
  </i>
  <template slot-scope="{ item }">
    <div class="name">{{ item.value }}</div>
    <span class="addr">{{ item.address }}</span>
  </template>
</el-autocomplete>

<style>
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>

<script>
  export default {
    data() {
      return {
        restaurants: [],
        state: ''
      };
    },
    methods: {
      querySearch(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      loadAll() {
        return [
          { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
          { "value": "Hot honey 首尔炸鸡（仙霞路）", "address": "上海市长宁区淞虹路661号" },
          { "value": "新旺角茶餐厅", "address": "上海市普陀区真北路988号创邑金沙谷6号楼113" },
          { "value": "泷千家(天山西路店)", "address": "天山西路438号" },
          { "value": "胖仙女纸杯蛋糕（上海凌空店）", "address": "上海市长宁区金钟路968号1幢18号楼一层商铺18-101" },
          { "value": "贡茶", "address": "上海市长宁区金钟路633号" },
          { "value": "豪大大香鸡排超级奶爸", "address": "上海市嘉定区曹安公路曹安路1685号" },
          { "value": "茶芝兰（奶茶，手抓饼）", "address": "上海市普陀区同普路1435号" },
          { "value": "十二泷町", "address": "上海市北翟路1444弄81号B幢-107" },
          { "value": "星移浓缩咖啡", "address": "上海市嘉定区新郁路817号" },
          { "value": "阿姨奶茶/豪大大", "address": "嘉定区曹安路1611号" },
          { "value": "新麦甜四季甜品炸鸡", "address": "嘉定区曹安公路2383弄55号" },
          { "value": "Monica摩托主题咖啡店", "address": "嘉定区江桥镇曹安公路2409号1F，2383弄62号1F" },
          { "value": "浮生若茶（凌空soho店）", "address": "上海长宁区金钟路968号9号楼地下一层" },
          { "value": "NONO JUICE  鲜榨果汁", "address": "上海市长宁区天山西路119号" },
          { "value": "CoCo都可(北新泾店）", "address": "上海市长宁区仙霞西路" },
          { "value": "快乐柠檬（神州智慧店）", "address": "上海市长宁区天山西路567号1层R117号店铺" },
          { "value": "Merci Paul cafe", "address": "上海市普陀区光复西路丹巴路28弄6号楼819" },
          { "value": "猫山王（西郊百联店）", "address": "上海市长宁区仙霞西路88号第一层G05-F01-1-306" },
          { "value": "枪会山", "address": "上海市普陀区棕榈路" },
          { "value": "纵食", "address": "元丰天山花园(东门) 双流路267号" },
          { "value": "钱记", "address": "上海市长宁区天山西路" },
          { "value": "壹杯加", "address": "上海市长宁区通协路" },
          { "value": "唦哇嘀咖", "address": "上海市长宁区新泾镇金钟路999号2幢（B幢）第01层第1-02A单元" },
          { "value": "爱茜茜里(西郊百联)", "address": "长宁区仙霞西路88号1305室" },
          { "value": "爱茜茜里(近铁广场)", "address": "上海市普陀区真北路818号近铁城市广场北区地下二楼N-B2-O2-C商铺" },
          { "value": "鲜果榨汁（金沙江路和美广店）", "address": "普陀区金沙江路2239号金沙和美广场B1-10-6" },
          { "value": "开心丽果（缤谷店）", "address": "上海市长宁区威宁路天山路341号" },
          { "value": "超级鸡车（丰庄路店）", "address": "上海市嘉定区丰庄路240号" },
          { "value": "妙生活果园（北新泾店）", "address": "长宁区新渔路144号" },
          { "value": "香宜度麻辣香锅", "address": "长宁区淞虹路148号" },
          { "value": "凡仔汉堡（老真北路店）", "address": "上海市普陀区老真北路160号" },
          { "value": "港式小铺", "address": "上海市长宁区金钟路968号15楼15-105室" },
          { "value": "蜀香源麻辣香锅（剑河路店）", "address": "剑河路443-1" },
          { "value": "北京饺子馆", "address": "长宁区北新泾街道天山西路490-1号" },
          { "value": "饭典*新简餐（凌空SOHO店）", "address": "上海市长宁区金钟路968号9号楼地下一层9-83室" },
          { "value": "焦耳·川式快餐（金钟路店）", "address": "上海市金钟路633号地下一层甲部" },
          { "value": "动力鸡车", "address": "长宁区仙霞西路299弄3号101B" },
          { "value": "浏阳蒸菜", "address": "天山西路430号" },
          { "value": "四海游龙（天山西路店）", "address": "上海市长宁区天山西路" },
          { "value": "樱花食堂（凌空店）", "address": "上海市长宁区金钟路968号15楼15-105室" },
          { "value": "壹分米客家传统调制米粉(天山店)", "address": "天山西路428号" },
          { "value": "福荣祥烧腊（平溪路店）", "address": "上海市长宁区协和路福泉路255弄57-73号" },
          { "value": "速记黄焖鸡米饭", "address": "上海市长宁区北新泾街道金钟路180号1层01号摊位" },
          { "value": "红辣椒麻辣烫", "address": "上海市长宁区天山西路492号" },
          { "value": "(小杨生煎)西郊百联餐厅", "address": "长宁区仙霞西路88号百联2楼" },
          { "value": "阳阳麻辣烫", "address": "天山西路389号" },
          { "value": "南拳妈妈龙虾盖浇饭", "address": "普陀区金沙江路1699号鑫乐惠美食广场A13" }
        ];
      },
      handleSelect(item) {
        console.log(item);
      },
      handleIconClick(ev) {
        console.log(ev);
      }
    },
    mounted() {
      this.restaurants = this.loadAll();
    }
  }
</script>
```
:::

### 远程搜索

从服务端搜索数据

:::demo
```html
<el-autocomplete
  v-model="state"
  :fetch-suggestions="querySearchAsync"
  placeholder="请输入内容"
  @select="handleSelect"
></el-autocomplete>
<script>
  export default {
    data() {
      return {
        restaurants: [],
        state: '',
        timeout:  null
      };
    },
    methods: {
      loadAll() {
        return [
          { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
          { "value": "Hot honey 首尔炸鸡（仙霞路）", "address": "上海市长宁区淞虹路661号" },
          { "value": "新旺角茶餐厅", "address": "上海市普陀区真北路988号创邑金沙谷6号楼113" },
          { "value": "泷千家(天山西路店)", "address": "天山西路438号" },
          { "value": "胖仙女纸杯蛋糕（上海凌空店）", "address": "上海市长宁区金钟路968号1幢18号楼一层商铺18-101" },
          { "value": "贡茶", "address": "上海市长宁区金钟路633号" },
          { "value": "豪大大香鸡排超级奶爸", "address": "上海市嘉定区曹安公路曹安路1685号" },
          { "value": "茶芝兰（奶茶，手抓饼）", "address": "上海市普陀区同普路1435号" },
          { "value": "十二泷町", "address": "上海市北翟路1444弄81号B幢-107" },
          { "value": "星移浓缩咖啡", "address": "上海市嘉定区新郁路817号" },
          { "value": "阿姨奶茶/豪大大", "address": "嘉定区曹安路1611号" },
          { "value": "新麦甜四季甜品炸鸡", "address": "嘉定区曹安公路2383弄55号" },
          { "value": "Monica摩托主题咖啡店", "address": "嘉定区江桥镇曹安公路2409号1F，2383弄62号1F" },
          { "value": "浮生若茶（凌空soho店）", "address": "上海长宁区金钟路968号9号楼地下一层" },
          { "value": "NONO JUICE  鲜榨果汁", "address": "上海市长宁区天山西路119号" },
          { "value": "CoCo都可(北新泾店）", "address": "上海市长宁区仙霞西路" },
          { "value": "快乐柠檬（神州智慧店）", "address": "上海市长宁区天山西路567号1层R117号店铺" },
          { "value": "Merci Paul cafe", "address": "上海市普陀区光复西路丹巴路28弄6号楼819" },
          { "value": "猫山王（西郊百联店）", "address": "上海市长宁区仙霞西路88号第一层G05-F01-1-306" },
          { "value": "枪会山", "address": "上海市普陀区棕榈路" },
          { "value": "纵食", "address": "元丰天山花园(东门) 双流路267号" },
          { "value": "钱记", "address": "上海市长宁区天山西路" },
          { "value": "壹杯加", "address": "上海市长宁区通协路" },
          { "value": "唦哇嘀咖", "address": "上海市长宁区新泾镇金钟路999号2幢（B幢）第01层第1-02A单元" },
          { "value": "爱茜茜里(西郊百联)", "address": "长宁区仙霞西路88号1305室" },
          { "value": "爱茜茜里(近铁广场)", "address": "上海市普陀区真北路818号近铁城市广场北区地下二楼N-B2-O2-C商铺" },
          { "value": "鲜果榨汁（金沙江路和美广店）", "address": "普陀区金沙江路2239号金沙和美广场B1-10-6" },
          { "value": "开心丽果（缤谷店）", "address": "上海市长宁区威宁路天山路341号" },
          { "value": "超级鸡车（丰庄路店）", "address": "上海市嘉定区丰庄路240号" },
          { "value": "妙生活果园（北新泾店）", "address": "长宁区新渔路144号" },
          { "value": "香宜度麻辣香锅", "address": "长宁区淞虹路148号" },
          { "value": "凡仔汉堡（老真北路店）", "address": "上海市普陀区老真北路160号" },
          { "value": "港式小铺", "address": "上海市长宁区金钟路968号15楼15-105室" },
          { "value": "蜀香源麻辣香锅（剑河路店）", "address": "剑河路443-1" },
          { "value": "北京饺子馆", "address": "长宁区北新泾街道天山西路490-1号" },
          { "value": "饭典*新简餐（凌空SOHO店）", "address": "上海市长宁区金钟路968号9号楼地下一层9-83室" },
          { "value": "焦耳·川式快餐（金钟路店）", "address": "上海市金钟路633号地下一层甲部" },
          { "value": "动力鸡车", "address": "长宁区仙霞西路299弄3号101B" },
          { "value": "浏阳蒸菜", "address": "天山西路430号" },
          { "value": "四海游龙（天山西路店）", "address": "上海市长宁区天山西路" },
          { "value": "樱花食堂（凌空店）", "address": "上海市长宁区金钟路968号15楼15-105室" },
          { "value": "壹分米客家传统调制米粉(天山店)", "address": "天山西路428号" },
          { "value": "福荣祥烧腊（平溪路店）", "address": "上海市长宁区协和路福泉路255弄57-73号" },
          { "value": "速记黄焖鸡米饭", "address": "上海市长宁区北新泾街道金钟路180号1层01号摊位" },
          { "value": "红辣椒麻辣烫", "address": "上海市长宁区天山西路492号" },
          { "value": "(小杨生煎)西郊百联餐厅", "address": "长宁区仙霞西路88号百联2楼" },
          { "value": "阳阳麻辣烫", "address": "天山西路389号" },
          { "value": "南拳妈妈龙虾盖浇饭", "address": "普陀区金沙江路1699号鑫乐惠美食广场A13" }
        ];
      },
      querySearchAsync(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 3000 * Math.random());
      },
      createStateFilter(queryString) {
        return (state) => {
          return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      handleSelect(item) {
        console.log(item);
      }
    },
    mounted() {
      this.restaurants = this.loadAll();
    }
  };
</script>
```
::: -->
<h3>输入长度限制</h3>
<demo-block>
        <div><p><code>maxlength</code> 和 <code>minlength</code> 是原生属性，用来限制输入框的字符长度，其中字符长度是用 Javascript 的字符串长度统计的。对于类型为 <code>text</code> 或 <code>textarea</code> 的输入框，在使用 <code>maxlength</code> 属性限制最大输入长度的同时，可通过设置 <code>show-word-limit</code> 属性来展示字数统计。</p>
</div>
        <template #source><element-demo9 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-input
  type=&quot;text&quot;
  placeholder=&quot;请输入内容&quot;
  v-model=&quot;text&quot;
  maxlength=&quot;10&quot;
  show-word-limit
&gt;
&lt;/el-input&gt;
&lt;div style=&quot;margin: 20px 0;&quot;&gt;&lt;/div&gt;
&lt;el-input
  type=&quot;textarea&quot;
  placeholder=&quot;请输入内容&quot;
  v-model=&quot;textarea&quot;
  maxlength=&quot;30&quot;
  show-word-limit
&gt;
&lt;/el-input&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const text = ref('')
      const textarea = ref('')

      return {
        text,
        textarea
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Input Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>type</td>
<td>类型</td>
<td>string</td>
<td>text，textarea 和其他 <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types">原生 input 的 type 值</a></td>
<td>text</td>
</tr>
<tr>
<td>modelValue / v-model</td>
<td>绑定值</td>
<td>string / number</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>maxlength</td>
<td>原生属性，最大输入长度</td>
<td>number</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>minlength</td>
<td>原生属性，最小输入长度</td>
<td>number</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>show-word-limit</td>
<td>是否显示输入字数统计，只在 <code>type = &quot;text&quot;</code> 或 <code>type = &quot;textarea&quot;</code> 时有效</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>placeholder</td>
<td>输入框占位文本</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>clearable</td>
<td>是否可清空</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>show-password</td>
<td>是否显示切换密码图标</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>disabled</td>
<td>禁用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>size</td>
<td>输入框尺寸，只在 <code>type!=&quot;textarea&quot;</code> 时有效</td>
<td>string</td>
<td>medium / small / mini</td>
<td>—</td>
</tr>
<tr>
<td>prefix-icon</td>
<td>输入框头部图标</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>suffix-icon</td>
<td>输入框尾部图标</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>rows</td>
<td>输入框行数，只对 <code>type=&quot;textarea&quot;</code> 有效</td>
<td>number</td>
<td>—</td>
<td>2</td>
</tr>
<tr>
<td>autosize</td>
<td>自适应内容高度，只对 <code>type=&quot;textarea&quot;</code> 有效，可传入对象，如，{ minRows: 2, maxRows: 6 }</td>
<td>boolean / object</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>autocomplete</td>
<td>原生属性，自动补全</td>
<td>string</td>
<td>on, off</td>
<td>off</td>
</tr>
<tr>
<td>auto-complete</td>
<td>下个主版本弃用</td>
<td>string</td>
<td>on, off</td>
<td>off</td>
</tr>
<tr>
<td>name</td>
<td>原生属性</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>readonly</td>
<td>原生属性，是否只读</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>max</td>
<td>原生属性，设置最大值</td>
<td>—</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>min</td>
<td>原生属性，设置最小值</td>
<td>—</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>step</td>
<td>原生属性，设置输入字段的合法数字间隔</td>
<td>—</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>resize</td>
<td>控制是否能被用户缩放</td>
<td>string</td>
<td>none, both, horizontal, vertical</td>
<td>—</td>
</tr>
<tr>
<td>autofocus</td>
<td>原生属性，自动获取焦点</td>
<td>boolean</td>
<td>true, false</td>
<td>false</td>
</tr>
<tr>
<td>form</td>
<td>原生属性</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>label</td>
<td>输入框关联的 label 文字</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>tabindex</td>
<td>输入框的 tabindex</td>
<td>string</td>
<td>-</td>
<td>-</td>
</tr>
<tr>
<td>validate-event</td>
<td>输入时是否触发表单的校验</td>
<td>boolean</td>
<td>-</td>
<td>true</td>
</tr>
</tbody>
</table>
<h3>Input Slots</h3>
<table>
<thead>
<tr>
<th>name</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>prefix</td>
<td>输入框头部内容，只对 <code>type=&quot;text&quot;</code> 有效</td>
</tr>
<tr>
<td>suffix</td>
<td>输入框尾部内容，只对 <code>type=&quot;text&quot;</code> 有效</td>
</tr>
<tr>
<td>prepend</td>
<td>输入框前置内容，只对 <code>type=&quot;text&quot;</code> 有效</td>
</tr>
<tr>
<td>append</td>
<td>输入框后置内容，只对 <code>type=&quot;text&quot;</code> 有效</td>
</tr>
</tbody>
</table>
<h3>Input Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>blur</td>
<td>在 Input 失去焦点时触发</td>
<td>(event: Event)</td>
</tr>
<tr>
<td>focus</td>
<td>在 Input 获得焦点时触发</td>
<td>(event: Event)</td>
</tr>
<tr>
<td>change</td>
<td>仅在输入框失去焦点或用户按下回车时触发</td>
<td>(value: string | number)</td>
</tr>
<tr>
<td>input</td>
<td>在 Input 值改变时触发</td>
<td>(value: string | number)</td>
</tr>
<tr>
<td>clear</td>
<td>在点击由 <code>clearable</code> 属性生成的清空按钮时触发</td>
<td>—</td>
</tr>
</tbody>
</table>
<h3>Input Methods</h3>
<table>
<thead>
<tr>
<th>方法名</th>
<th>说明</th>
<th>参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>focus</td>
<td>使 input 获取焦点</td>
<td>—</td>
</tr>
<tr>
<td>blur</td>
<td>使 input 失去焦点</td>
<td>—</td>
</tr>
<tr>
<td>select</td>
<td>选中 input 中的文字</td>
<td>—</td>
</tr>
</tbody>
</table>
<!-- ### Autocomplete Attributes

| 参数          | 说明            | 类型            | 可选值                 | 默认值   |
|-------------  |---------------- |---------------- |---------------------- |-------- |
| placeholder   | 输入框占位文本   | string          | — | — |
| disabled      | 禁用            | boolean         | — | false   |
| value-key | 输入建议对象中用于显示的键名 | string | — | value |
| value         | 必填值，输入绑定值   | string  | — | — |
| debounce      | 获取输入建议的去抖延时 | number         | — | 300 |
| placement     | 菜单弹出位置 | string         | top / top-start / top-end / bottom / bottom-start / bottom-end | bottom-start |
| fetch-suggestions | 返回输入建议的方法，仅当你的输入建议数据 resolve 时，通过调用 callback(data:[]) 来返回它  | Function(queryString, callback)  | — | — |
| popper-class | Autocomplete 下拉列表的类名 | string | — | — |
| trigger-on-focus | 是否在输入框 focus 时显示建议列表 | boolean | — | true |
| name | 原生属性 | string | — | — |
| select-when-unmatched | 在输入没有任何匹配建议的情况下，按下回车是否触发 `select` 事件 | boolean | — | false |
| label | 输入框关联的label文字 | string | — | — |
| prefix-icon | 输入框头部图标 | string | — | — |
| suffix-icon | 输入框尾部图标 | string | — | — |
| hide-loading | 是否隐藏远程加载时的加载图标 | boolean | — | false |
| popper-append-to-body | 是否将下拉列表插入至 body 元素。在下拉列表的定位出现问题时，可将该属性设置为 false | boolean | - | true |
| highlight-first-item | 是否默认突出显示远程搜索建议中的第一项 | boolean | — | false |

### Autocomplete Slots
| name | 说明 |
|------|--------|
| prefix | 输入框头部内容 |
| suffix | 输入框尾部内容 |
| prepend | 输入框前置内容 |
| append | 输入框后置内容 |

### Autocomplete Scoped Slot
| name | 说明 |
|------|--------|
| — | 自定义输入建议，参数为 { item } |

### Autocomplete Events
| 事件名称 | 说明 | 回调参数 |
|---------|--------|---------|
| select | 点击选中建议项时触发 | 选中建议项 |
| change | 在 Input 值改变时触发 | (value: string \| number) |

### Autocomplete Methods
| 方法名 | 说明 | 参数 |
| ---- | ---- | ---- |
| focus | 使 input 获取焦点 | - | -->

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createBlock(_component_el_input, {
    modelValue: _ctx.input,
    "onUpdate:modelValue": $event => (_ctx.input = $event),
    placeholder: "请输入内容"
  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const input = ref('')

      return { input }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createBlock(_component_el_input, {
    placeholder: "请输入内容",
    modelValue: _ctx.input,
    "onUpdate:modelValue": $event => (_ctx.input = $event),
    disabled: true
  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const input = ref('')

      return { input }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createBlock(_component_el_input, {
    placeholder: "请输入内容",
    modelValue: _ctx.input,
    "onUpdate:modelValue": $event => (_ctx.input = $event),
    clearable: ""
  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const input = ref('')

      return { input }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createBlock(_component_el_input, {
    placeholder: "请输入密码",
    modelValue: _ctx.input,
    "onUpdate:modelValue": $event => (_ctx.input = $event),
    "show-password": ""
  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const input = ref('')

      return { input }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, createTextVNode: _createTextVNode, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", { class: "demo-input-suffix" }, [
      _createTextVNode(" 属性方式： "),
      _createVNode(_component_el_input, {
        placeholder: "请选择日期",
        "suffix-icon": "el-icon-date",
        modelValue: _ctx.input1,
        "onUpdate:modelValue": $event => (_ctx.input1 = $event)
      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
      _createVNode(_component_el_input, {
        placeholder: "请输入内容",
        "prefix-icon": "el-icon-search",
        modelValue: _ctx.input2,
        "onUpdate:modelValue": $event => (_ctx.input2 = $event)
      }, null, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { class: "demo-input-suffix" }, [
      _createTextVNode(" slot 方式： "),
      _createVNode(_component_el_input, {
        placeholder: "请选择日期",
        modelValue: _ctx.input3,
        "onUpdate:modelValue": $event => (_ctx.input3 = $event)
      }, {
        suffix: _withCtx(() => [
          _createVNode("i", { class: "el-input__icon el-icon-date" })
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue"]),
      _createVNode(_component_el_input, {
        placeholder: "请输入内容",
        modelValue: _ctx.input4,
        "onUpdate:modelValue": $event => (_ctx.input4 = $event)
      }, {
        prefix: _withCtx(() => [
          _createVNode("i", { class: "el-input__icon el-icon-search" })
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue"])
    ])
  ], 64))
}
  
    const democomponentExport = {
    data() {
      return {
        input1: '',
        input2: '',
        input3: '',
        input4: ''
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo5": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createBlock(_component_el_input, {
    type: "textarea",
    rows: 2,
    placeholder: "请输入内容",
    modelValue: _ctx.textarea,
    "onUpdate:modelValue": $event => (_ctx.textarea = $event)
  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const textarea = ref('')

      return { textarea }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo6": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_input, {
      type: "textarea",
      autosize: "",
      placeholder: "请输入内容",
      modelValue: _ctx.textarea1,
      "onUpdate:modelValue": $event => (_ctx.textarea1 = $event)
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode("div", { style: {"margin":"20px 0"} }),
    _createVNode(_component_el_input, {
      type: "textarea",
      autosize: { minRows: 2, maxRows: 4},
      placeholder: "请输入内容",
      modelValue: _ctx.textarea2,
      "onUpdate:modelValue": $event => (_ctx.textarea2 = $event)
    }, null, 8, ["modelValue", "onUpdate:modelValue"])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const textarea1 = ref('')
      const textarea2 = ref('')

      return {
        textarea1,
        textarea2
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo7": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_el_input, {
        placeholder: "请输入内容",
        modelValue: _ctx.input1,
        "onUpdate:modelValue": $event => (_ctx.input1 = $event)
      }, {
        prepend: _withCtx(() => [
          _createTextVNode("Http://")
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { style: {"margin-top":"15px"} }, [
      _createVNode(_component_el_input, {
        placeholder: "请输入内容",
        modelValue: _ctx.input2,
        "onUpdate:modelValue": $event => (_ctx.input2 = $event)
      }, {
        append: _withCtx(() => [
          _createTextVNode(".com")
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode("div", { style: {"margin-top":"15px"} }, [
      _createVNode(_component_el_input, {
        placeholder: "请输入内容",
        modelValue: _ctx.input3,
        "onUpdate:modelValue": $event => (_ctx.input3 = $event),
        class: "input-with-select"
      }, {
        prepend: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.select,
            "onUpdate:modelValue": $event => (_ctx.select = $event),
            placeholder: "请选择"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: "餐厅名",
                value: "1"
              }),
              _createVNode(_component_el_option, {
                label: "订单号",
                value: "2"
              }),
              _createVNode(_component_el_option, {
                label: "用户电话",
                value: "3"
              })
            ]),
            _: 1
          }, 8, ["modelValue", "onUpdate:modelValue"])
        ]),
        append: _withCtx(() => [
          _createVNode(_component_el_button, { icon: "el-icon-search" })
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue"])
    ])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const input1 = ref('')
      const input2 = ref('')
      const input3 = ref('')
      const select = ref('')

      return {
        input1,
        input2,
        input3,
        select
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo8": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createBlock("div", { class: "demo-input-size" }, [
    _createVNode(_component_el_input, {
      placeholder: "请输入内容",
      "suffix-icon": "el-icon-date",
      modelValue: _ctx.input1,
      "onUpdate:modelValue": $event => (_ctx.input1 = $event)
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_el_input, {
      size: "medium",
      placeholder: "请输入内容",
      "suffix-icon": "el-icon-date",
      modelValue: _ctx.input2,
      "onUpdate:modelValue": $event => (_ctx.input2 = $event)
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_el_input, {
      size: "small",
      placeholder: "请输入内容",
      "suffix-icon": "el-icon-date",
      modelValue: _ctx.input3,
      "onUpdate:modelValue": $event => (_ctx.input3 = $event)
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_el_input, {
      size: "mini",
      placeholder: "请输入内容",
      "suffix-icon": "el-icon-date",
      modelValue: _ctx.input4,
      "onUpdate:modelValue": $event => (_ctx.input4 = $event)
    }, null, 8, ["modelValue", "onUpdate:modelValue"])
  ]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const input1 = ref('')
      const input2 = ref('')
      const input3 = ref('')
      const input4 = ref('')

      return {
        input1,
        input2,
        input3,
        input4
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo9": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_input, {
      type: "text",
      placeholder: "请输入内容",
      modelValue: _ctx.text,
      "onUpdate:modelValue": $event => (_ctx.text = $event),
      maxlength: "10",
      "show-word-limit": ""
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode("div", { style: {"margin":"20px 0"} }),
    _createVNode(_component_el_input, {
      type: "textarea",
      placeholder: "请输入内容",
      modelValue: _ctx.textarea,
      "onUpdate:modelValue": $event => (_ctx.textarea = $event),
      maxlength: "30",
      "show-word-limit": ""
    }, null, 8, ["modelValue", "onUpdate:modelValue"])
  ], 64))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const text = ref('')
      const textarea = ref('')

      return {
        text,
        textarea
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  